import { Component } from 'vue-property-decorator'

import { AuthService, CommonBaseMixin, inject, NotificationServiceInterface } from 'booksprout-app'
import { HasIndex, NotificationDto } from 'booksprout'

@Component
export default class UserEmailActions extends CommonBaseMixin {
  @inject('AuthService')
  authService!: AuthService

  @inject('NotificationService')
  notificationService!: NotificationServiceInterface<NotificationDto>

  actioned = false

  get isUnsubscribing () {
    return this.$route.query.unsubKey !== void 0
  }

  unsubscribe () {
    const emailType = this.$route.query.emailType + ''
    const key = (this.MODULE_CONSTANTS.NOTIFICATIONS.UNSUB_PREFERENCE_KEYS as HasIndex)[emailType]
    const unsubId = this.$route.query.unsubKey + ''

    if (key && unsubId) {
      this.notificationService.unsubscribe({
        key,
        unsubId
      }).then(() => {
        const translatedKey = this.$t(`modules.notifications.unsubscribe.${key}`)
        this.showMessage(this.$t('modules.notifications.unsubscribe.confirmationMessage', { notificationType: translatedKey } ))
        this.actioned = true
      }).catch(e => {
        this.showError(e.message)
      }).finally(() => {
        // https://app.shortcut.com/booksprout/story/5985/bs-all-design-needed-for-unsubscribe-page
        if (this.isAuthenticated) {
          void this.$router.push('/settings/email')
        } else {
          void this.$router.push('/login')
        }
      })
    } else {
      this.showError(this.$tc('system.errors.notEnoughInformation'))
    }
  }

  created () {
    if (this.isUnsubscribing) {
      this.unsubscribe()
    }
  }
}
